import React, { useMemo, useState, useEffect } from 'react';
import ChartBreakdown from './ChartBreakdown';
import ChartAntibiogram from './ChartAntibiogram';
import ChartShape from './ChartShape';
import ChartAntibiotics from './ChartAntibiotics';
import { Divider, Typography, Box } from '@material-ui/core';
import axios from 'axios';
import { ApiBaseUrl } from '../../app/helpers';
import Skeleton from '@material-ui/lab/Skeleton';
import WarningTooltip from './WarningTooltip';


function onlyUnique(value:any, index:any, self:any) {
	return self.indexOf(value) === index;
  }

interface FeedInfo {
	total: number
	resistant: number,
	susceptible: number,
	pathogenid: number,
	pathogen: string,
	antibioticsid: number,
	antibiotic: string,
	pathogentypeid: number,
	pathogentype: string,
	pathogentypecolour: string,
	shape: string
}

interface FeedInfoCollection {
	level: string
	data: FeedInfo[]
}

interface DashboardCardProps {
	label: any[]
	antibiogram: FeedInfoCollection[]
	data: string
	biosystem: number,
	searchGender: any,
	searchAge: any,
	searchRegion: any
}

function getCookie(name:string) : string | undefined {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0){
			return c.substring(nameEQ.length,c.length);
		}
    }
    return undefined;
}

const GenerateAntibiogram = (antibiogram: any, selectedAntibiogramLevel: any, selectedPathogenTypeId: any) => {
	var antibiogramData = {} as any;
		var antibiogramKeyCollection = [] as any[];
		var antibioticsData = {} as any;
		var antibioticsKeyCollection = [] as any[];
		var pathogenTypeColourLookup = {} as any;
	
		var shapeData = {
			"Rod": 0,
			"Cocci": 0
		} as any;
	
		var cocci = {
			total: 0
		} as any;
		var cocciKeyCollection = [] as any;
	
		var rod = {
			total: 0
		} as any;
		var rodKeyCollection = [] as any;
	
	
		for (let index = 0; index < antibiogram.length; index++) {
			const element = antibiogram[index];
	
			if( selectedAntibiogramLevel && selectedAntibiogramLevel !== element.level )
			{
				continue;
			}
		
			if( typeof antibiogramData[element.level] === 'undefined' )
			{
				antibiogramData[element.level] = {};
			}
			
			antibiogramData[element.level].level = element.level;
	
			for (let innerIndex = 0; innerIndex < element.data.length; innerIndex++) {
				const inner = element.data[innerIndex] as any;
	
				if( selectedPathogenTypeId && selectedPathogenTypeId !== inner.pathogentypeid  )
				{
					continue;
				}
	
				if( typeof antibioticsData[inner.antibiotic] === 'undefined' )
				{
					antibioticsData[inner.antibiotic] = 0;
					antibioticsData[inner.antibiotic+'Colour'] = inner.pathogentypecolour;
					antibioticsData[inner.antibiotic+'Total'] = 0;
					antibioticsData[inner.antibiotic+'Gram Positive'] = 0;
					antibioticsData[inner.antibiotic+'Gram Negative'] = 0;
					antibioticsData[inner.antibiotic+'Gram PositiveTotal'] = 0;
					antibioticsData[inner.antibiotic+'Gram NegativeTotal'] = 0;
					antibioticsData[inner.antibiotic+'Susceptible'] = 0;
					antibioticsKeyCollection.push(inner.antibiotic);
				}
	
				antibioticsData[inner.antibiotic+'Total'] += inner.total;
				antibioticsData[inner.antibiotic+'Susceptible'] += inner.susceptible;
				antibioticsData[inner.antibiotic] += inner.susceptible;
				antibioticsData[inner.antibiotic+inner['pathogentype']] += inner.susceptible;
				antibioticsData[inner.antibiotic+inner['pathogentype']+'Total'] += inner.total;
				shapeData[inner.shape] += inner.total;

	
				if( inner.shape.toLowerCase() === "cocci" )
				{
					if( typeof cocci[inner.genera] === 'undefined')
					{
						cocci[inner.genera] = 0;
						cocciKeyCollection.push({
							name: inner.genera,
							generaid: inner.generaid,
							colour: inner.pathogentypecolour
						});
					}
					cocci.total += inner.total;
					cocci[inner.genera] += inner.total;
				}
	
				if( inner.shape.toLowerCase() === "rod" )
				{
					if( typeof rod[inner.genera] === 'undefined')
					{
						rod[inner.genera] = 0;
						rodKeyCollection.push({
							name: inner.genera,
							generaid: inner.generaid,
							colour: inner.pathogentypecolour
						});
					}
					rod.total += inner.total;
					rod[inner.genera] += inner.total;
				}
	
				if( typeof antibiogramData[element.level][inner.pathogentype] === 'undefined' )
				{
					antibiogramData[element.level][inner.pathogentype] = 0;
					antibiogramData[element.level][inner.pathogentype+'Total'] = 0;
					antibiogramData[element.level][inner.pathogentype+'Susceptible'] = 0;
					pathogenTypeColourLookup[inner.pathogentype] = inner.pathogentypecolour;
					antibiogramKeyCollection.push(inner.pathogentype);
				}

				antibiogramData[element.level][inner.pathogentype] += inner.susceptible;
				antibiogramData[element.level][inner.pathogentype+'Susceptible'] += inner.susceptible;
				antibiogramData[element.level][inner.pathogentype+'Total'] += inner.total;

			}
		}

		var antibioticsKey = antibioticsKeyCollection.filter(onlyUnique);
		var antibiogramKey = antibiogramKeyCollection.filter(onlyUnique);

		var shapeDataTotal = shapeData['Cocci'] + shapeData['Rod'];
		shapeData['Cocci'] = ((shapeData['Cocci'] / shapeDataTotal) * 100).toFixed(0);
		shapeData['Rod'] = ((shapeData['Rod'] / shapeDataTotal) * 100).toFixed(0);

		var antibiogramHasWarning = false;

		var antibiogramDataResult = Object.keys(antibiogramData).sort().map(function(key){
			var returnObj = antibiogramData[key];
			for (let index = 0; index < antibiogramKey.length; index++) {
				const antibiogramKeyLevel = antibiogramKey[index];
				antibiogramData[key][antibiogramKeyLevel] = (antibiogramData[key][antibiogramKeyLevel] / antibiogramData[key][antibiogramKeyLevel+'Total'] * 100).toFixed(1);
				antibiogramData[key][antibiogramKeyLevel+'Total'] = antibiogramData[key][antibiogramKeyLevel+'Total'];
				antibiogramData[key][antibiogramKeyLevel+'Susceptible'] = antibiogramData[key][antibiogramKeyLevel+'Susceptible'];

				if( antibiogramData[key][antibiogramKeyLevel+'Total'] < 100 )
				{
					antibiogramHasWarning = true;
				}
			}
			return returnObj;
		});

		for (let index = 0; index < cocciKeyCollection.length; index++) {
			var key = cocciKeyCollection[index];
			cocci[key.name] = ((cocci[key.name] / cocci.total) * 100).toFixed(1);			
		}

		for (let index = 0; index < rodKeyCollection.length; index++) {
			var rodKey = rodKeyCollection[index];
			rod[rodKey.name] = ((rod[rodKey.name] / rod.total) * 100).toFixed(1);			
		}



		var antibioticsDataCollection = [] as any[];
		for (let index = 0; index < antibioticsKey.length; index++) {
			var antibioticsKeyLoop = antibioticsKey[index];
			antibioticsData[antibioticsKeyLoop] = parseFloat(((antibioticsData[antibioticsKeyLoop] /antibioticsData[antibioticsKeyLoop+'Total']) * 100).toFixed(1));
			var obj = {};
			obj['value'] = antibioticsData[antibioticsKeyLoop];
			obj['total'] = antibioticsData[antibioticsKeyLoop+'Total'];
			obj['susceptible'] = antibioticsData[antibioticsKeyLoop+'Susceptible'];

			obj['antibiotic'] = antibioticsKeyLoop;

			obj['gramPositiveTotal'] = antibioticsData[antibioticsKeyLoop+'Gram PositiveTotal'];
			obj['gramPositive'] = antibioticsData[antibioticsKeyLoop+'Gram Positive'];

			obj['gramNegativeTotal'] = antibioticsData[antibioticsKeyLoop+'Gram NegativeTotal'];
			obj['gramNegative'] = antibioticsData[antibioticsKeyLoop+'Gram Negative'];

			obj['gramPositiveValue'] = parseFloat(((obj['gramPositive'] / obj['gramPositiveTotal']) * 100).toFixed(1));
			obj['gramNegativeValue'] = parseFloat(((obj['gramNegative'] / obj['gramNegativeTotal']) * 100).toFixed(1));
			
			antibioticsDataCollection.push(obj);		
		}

		antibioticsDataCollection = antibioticsDataCollection.sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0)); 

		
		return {
			antibiogramHasWarning: antibiogramHasWarning,
			antibiogram: antibiogramDataResult,
			shapeData: shapeData,
			antibioticsData: antibioticsDataCollection,//[antibioticsData],
			antibioticsKeyCollection: antibioticsKey,
			antibiogramKeyCollection: antibiogramKey.sort(),
			cocciKeyCollection: cocciKeyCollection,
			rodKeyCollection: rodKeyCollection,
			rod: rod,
			cocci: cocci,
			pathogenTypeColourLookup: pathogenTypeColourLookup
		}
}


const DashboardCard = (props:DashboardCardProps) => {
	const [selectedPathogenTypeId, setSelectedPathogenTypeId] = useState();
	const [selectedGenusId, setSelectedGenusId] = useState({} as any);
	const [altBiogram, setAltBiogram] = useState(null as any);

	const [altBiogramLoading, setAltBiogramLoading] = useState(false);
	const [selectedAntibiogramLevel, setSelectedAntibiogramLevel] = useState();
	const handleBreakdownClick = (value: any) => setSelectedPathogenTypeId(value);
	const handleShapeClick = (value: any) => {setSelectedGenusId(value); setSelectedAntibiogramLevel(null)};
	const handleAntibiogramClick = (value:any) => {
		if( value === selectedAntibiogramLevel )
		{
			value = null;
		}

		setSelectedAntibiogramLevel(value);
	}

	useEffect(() => {
		if( selectedGenusId == null || selectedGenusId.generaid == null )
		{
			return setAltBiogram(null);
		}

		setAltBiogramLoading(true);

		axios.get(`${ApiBaseUrl}/api/searchAntibiogram`, {
			params: {
				biosystem: 11,
				gender: props.searchGender,
				region: props.searchRegion,
				age: props.searchAge,
				genera: selectedGenusId.generaid
			},
			headers: {
				Authorization: `Bearer ${getCookie('jwt:auth')}`
			}
		}).then((response)=>{
			setAltBiogram(response.data);
			setAltBiogramLoading(false);
		});

	}, [props.searchAge, props.searchGender, props.searchRegion, selectedGenusId]);

	const chartData = useMemo(() => {
		return GenerateAntibiogram(props.antibiogram, selectedAntibiogramLevel, selectedPathogenTypeId);
	}, [props.antibiogram, selectedAntibiogramLevel, selectedPathogenTypeId],
	);

	const altChartData = useMemo(() => {
		if( altBiogram=== null )
		{
			return null;
		}

		return GenerateAntibiogram(altBiogram, selectedAntibiogramLevel, selectedPathogenTypeId);
	}, [altBiogram, selectedAntibiogramLevel, selectedPathogenTypeId],
	);

	const renderAntibiogram = () => {

		if( altBiogramLoading )
		{
			return <>
				<Box mt={2} mb={2} ><Divider variant="middle" /></Box>
				<Typography variant="h3">Antibiogram {selectedGenusId !== null && <>- {selectedGenusId.name}</>}</Typography>
				<Box ml={0} style={{marginTop:'2px'}}><Skeleton animation="wave" variant="rect" width="92%" height={20} /></Box>
				<Box ml={0} style={{marginTop:'6px'}}><Skeleton animation="wave" variant="rect" width="90%" height={20} /></Box>
				<Box ml={0} style={{marginTop:'6px'}}><Skeleton animation="wave" variant="rect" width="87%" height={20} /></Box>
			</>
		}


		if( altChartData != null )
		{
			return <>
				<Box mt={2} mb={2} ><Divider variant="middle" /></Box>
				<Typography variant="h3">Antibiogram {selectedGenusId !== null && <>- {selectedGenusId.name}</>}{altChartData.antibiogramHasWarning && <WarningTooltip />}</Typography>
				<ChartAntibiogram data={altChartData.antibiogram} onClick={handleAntibiogramClick} keyCollection={altChartData.antibiogramKeyCollection} colourLookup={altChartData.pathogenTypeColourLookup} />  
				{selectedAntibiogramLevel && <ChartAntibiotics data={altChartData.antibioticsData} keyCollection={altChartData.antibioticsKeyCollection} selectedLevel={selectedAntibiogramLevel} selectedPathogen={selectedPathogenTypeId} />}
			</>
		}

		return <>
			<Box mt={2} mb={2} ><Divider variant="middle" /></Box>
			<Typography variant="h3">Antibiogram {chartData.antibiogramHasWarning && <WarningTooltip />}</Typography>
			<ChartAntibiogram data={chartData.antibiogram} onClick={handleAntibiogramClick} keyCollection={chartData.antibiogramKeyCollection} colourLookup={chartData.pathogenTypeColourLookup} />  
			{selectedAntibiogramLevel && <ChartAntibiotics data={chartData.antibioticsData} keyCollection={chartData.antibioticsKeyCollection} selectedLevel={selectedAntibiogramLevel} selectedPathogen={selectedPathogenTypeId} />}
		</>
	};

	return (
		<>
			<Typography variant="h2">{props.label}</Typography>
			<Divider />
			<ChartBreakdown searchResult={props.data} onClick={handleBreakdownClick} selected={selectedPathogenTypeId} />
			{selectedPathogenTypeId && <ChartShape shapeData={chartData.shapeData} selected={selectedGenusId} onClick={handleShapeClick} cocci={chartData.cocci} cocciKeyCollection={chartData.cocciKeyCollection} rod={chartData.rod} rodKeyCollection={chartData.rodKeyCollection} />}
			{renderAntibiogram()}
			
		</>
	);
}

export default DashboardCard;