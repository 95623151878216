import React from "react";
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const ShowPercentTooltip = (props:any) => {
	if (props.payload && props.payload[0] != null) 
	{
		const newPayload = props.payload.map((payload)=>{
			var newPayload =  { ...payload };
			newPayload.value = payload.value + '%'
			return newPayload;
		});

		return <DefaultTooltipContent {...props} label={""} payload={newPayload} />;
	}
  
	return <DefaultTooltipContent {...props} />;
};

export default ShowPercentTooltip;