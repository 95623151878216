import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getUser, signout } from '../../features/User/UserSlice';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Popover,
  colors,
  Button,
  Avatar,
  Typography,
  Divider,
  Grid,
  Paper
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../components/Logo';
import { makeStyles } from '@material-ui/core/styles';
import janusColors from '../../theme/janusColors';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';



// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  navLink: {
	color: colors.common.white,
	marginLeft: '3em',
	fontSize: '13px',
	fontWeight: '600',
	"&:hover": {
		color: janusColors.purple
	}
  },
  buttonLink: {
	  color: colors.common.white,
	  fontSize: '13px',
		fontWeight: '600',
		textTransform: 'none',
	  "&:hover": {
		color: janusColors.purple,
		"&::after": {
			borderTop: '7px solid ' + janusColors.purple
		}
		},
		"&::after": {
			content: '" "',
			width: 0, 
			height: 0, 
			marginLeft: '5px',
			borderLeft: '5px solid transparent',
			borderRight: '5px solid transparent',
			borderTop: '7px solid ' + colors.common.white
		}
  },
  paper: {
	margin: `${theme.spacing(1)}px auto`,
	padding: theme.spacing(2),
	boxShadow: 'none',
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles() as any;
  const user = useSelector((state: RootState) => getUser(state));
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSignOutClick = () => {
	dispatch(signout({}));
	}

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
		<Toolbar>
			<RouterLink to="/"><Logo /></RouterLink>
			
			<Hidden smDown>
				<Box alignItems="center" style={{height: '26px'}}>
					<RouterLink className={classes.navLink} to="/">Search</RouterLink>
					<a className={classes.navLink} href="https://doc.janus.healthcare/introduction-video" target="_blank" rel="noreferrer noopener">Learn</a>
				</Box>
				<Box flexGrow={1} />
				<Button aria-describedby={id} className={classes.buttonLink} onClick={handleClick}>{user.name}</Button>
				<Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
					anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
					}}
					transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
					}}
				>
					<Paper className={classes.paper}>
						<div style={{display: 'flex', marginBottom: '1em'}}>
							<Box pl={2} pr={2}>
								<Avatar className={classes.avatar} component={RouterLink} onClick={handleClose} src="/static/images/avatars/avatar_6.png" to="/settings" />
							</Box>
							<Box>
								<Typography className={classes.name} color="textPrimary" variant="h5">{user.name}</Typography>
								<Typography color="textSecondary" variant="body2">{user.email}</Typography>
								<Button variant="contained" color="default" size="small" onClick={handleClose} style={{marginTop:7}} component={RouterLink} to="/settings">View profile</Button>
							</Box>
						</div>
						

				
					<Divider />
					<Button onClick={handleSignOutClick}>Sign out</Button>
					</Paper>
				</Popover>
			</Hidden>
			
			<Hidden mdUp>
				<Box flexGrow={1} />
				<IconButton
					color="inherit"
					onClick={onMobileNavOpen}
				>
					<MenuIcon />
				</IconButton>
			</Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;


