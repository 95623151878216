import { configureStore, ThunkAction, Action, getDefaultMiddleware  } from '@reduxjs/toolkit';
import configReducer from '../features/Settings/SettingsSlice';
import userReducer from '../features/User/UserSlice';
import searchReducer from '../features/Dashboard/SearchSlice';


export const store = configureStore({
	reducer: {
		config: configReducer,
		user: userReducer,
		search: searchReducer,
	},
	middleware: getDefaultMiddleware({
		serializableCheck: false,
	}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
