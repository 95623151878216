import React, { useCallback, useState } from "react";
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

import {
	PieChart, Pie, Cell, ResponsiveContainer, Legend, Sector, Tooltip 
  } from 'recharts';
  import FaceIcon from '@material-ui/icons/Face';
  import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
	Card,
	CardContent,
	Typography,
	Chip,
	Box
  } from '@material-ui/core';


  const ShowPercentTooltip = (props:any) => {
	if (props.payload && props.payload[0] != null) 
	{
		var total = 0;
		for (let index = 0; index < props.data.length; index++) {
			total+= props.data[index].total;
			
		}

		const newPayload = props.data.map((payload)=>{
			var percent = (payload.total / total) * 100
			var newPayload =  { 
				color: payload.colour,
				value: `${payload.pathogentype} : ${percent.toFixed(0)}% (${payload.total}/${total})`
			 };
			return newPayload;
		});

		return <DefaultTooltipContent {...props} label={""} payload={newPayload} />;
	}
  
	return <DefaultTooltipContent {...props} />;
};


  const renderActiveShape = props => {
	const RADIAN = Math.PI / 180;
	const {
	  cx,
	  cy,
	  innerRadius,
	  outerRadius,
	  startAngle,
	  endAngle,
	  midAngle
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius - 59) * cos;
	const sy = cy + (outerRadius - 59) * sin;
	return (
	  <Sector
		cx={sx}
		cy={sy}
		innerRadius={innerRadius}
		outerRadius={outerRadius}
		startAngle={startAngle}
		endAngle={endAngle}
		stroke="black"
		strokeWidth={2}
		fill={props.colour}
	  />
	);
  };



const ChartBreakdown = (props:any) => {
	const [activeIndex, setActiveIndex] = useState(-1);
	const onMouseOver = useCallback((data, index) => {
	  setActiveIndex(index);
	}, []);
	const onMouseLeave = useCallback((data, index) => {
	  setActiveIndex(-1);
	}, []);

	//const pathogenLookup = useSelector((state: RootState) => getPathogenLookup(state));
	if( props.searchResult.length === 0 )
	{
		return null;
	}


	const CustomizedLegend = (legendProps:any) => {
		return <Box display="flex" justifyContent="center">{legendProps.payload.map((data, index)=>{
			const handleClick = () => {
				if( props.selected )
				{
					props.onClick(null)
				}
				else
				{
					props.onClick(data.payload.pathogentypeid);
				}
			}

			var opacity= 1;
			if( props.selected && props.selected !== data.payload.pathogentypeid )
			{
				opacity= 0.2;
			}

			return <Chip key={index} style={{opacity: opacity}} onClick={handleClick} icon={<FiberManualRecordIcon style={{ color: data.color }} />} label={data.value} />
		})}
		</Box>
  };

	const handleClick = (data: any) => {
		if( props.selected )
		{
			props.onClick(null)
		}
		else
		{
			props.onClick(data.payload.payload.pathogentypeid);
		}
	}

	// var data = props.searchResult.filter((record:any)=>{
	// 	if( props.selected )
	// 	{
	// 		return record.pathogentypeid === props.selected;
	// 	}

	// 	return true;
	// });

	return (	
		<ResponsiveContainer height={200} width="100%">
			<PieChart>
				<Pie
					activeIndex={activeIndex}
					onMouseOver={onMouseOver}
					onMouseLeave={onMouseLeave}
				dataKey="total" nameKey="pathogentype" onClick={handleClick} data={props.searchResult} outerRadius={60} fill="#8884d8" animationBegin={0} animationDuration={500}
					activeShape={renderActiveShape}
				>
					{props.searchResult.map((entry:any) => {
						var opacity= 1;
						if( props.selected && props.selected !== entry.pathogentypeid )
						{
							opacity= 0.2;
						}
						return <Cell fill={entry.colour} key={entry.pathogentypeid} style={{opacity: opacity}} />
					})}

				</Pie>
				<Legend content={<CustomizedLegend />} />
				<Tooltip content={<ShowPercentTooltip data={props.searchResult}/>} />


			</PieChart>
		</ResponsiveContainer>
	);
}

export default ChartBreakdown;