/* eslint-disable import/no-anonymous-default-export */
const barlowFont =  "'Barlow', sans-serif";


export default {
	fontFamily: barlowFont,
	h2: {
		fontFamily: barlowFont,
		fontWeight: 600,
		fontSize: 24,
		marginBottom: '1em'
	},
	h3: {
		fontFamily: barlowFont,
		fontWeight: 600,
		fontSize: 18,
		marginBottom: '0.5em'
	},
	h5: {
		fontFamily: barlowFont,
		fontWeight: 600,
		fontSize: 13,
	},
	body2: {
		fontSize: 11,
	},
	subtitle2: {
		marginTop: '1em',
		marginBottom: '1em',
		fontSize: 13,
		fontWeight: 600,
	},
	button: {
		textTransform: 'none',
	}
};
