import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
	title: string,
	className: string
};
const Page: React.FC<Props> = forwardRef<HTMLDivElement, Props>(
  (props, ref) => (
	<div ref={ref} {...props}>
	{/* <Helmet>
	  <title>{props.title}</title>
	</Helmet> */}
	{props.children}
  </div>
  )
);

export default Page;
