import React from 'react';

const Logo = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      alt="Logo"
	  src="/logo_dark.svg"
	  style={{height: '26px'}}
      {...props}
    />
  );
};

export default Logo;
