// @ts-nocheck
import React, { FunctionComponent, Props } from 'react';
import {Route, Navigate} from 'react-router';
import LoginView from '../features/User/LoginView';
import { getUser } from '../features/User/UserSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import MainLayout from '../layouts/MainLayout';


interface AuthRouteProps{
	element:any;
	path:string;
	children?:any;
  }
  
  export default function ProtectedRoute({element, path, children}:AuthRouteProps){
	const user = useSelector((state: RootState) => getUser(state));
	const isLoggedIn = user === null ? false : true;
  
	return isLoggedIn?(<Route path={path} element={element}>{children}</Route>): (<LoginView/> )
  }
