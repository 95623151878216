import React from "react";
import { Typography, Box, Chip } from '@material-ui/core';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Cell } from 'recharts';
import { ColourHash } from '../../app/helpers';
import ShowPercentTooltip from './ShowPercentTooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


interface ChartShapeProps {
	shapeData:any
	cocci:any
	cocciKeyCollection:any
	rod:any
	rodKeyCollection:any,
	selected: any,
	onClick: Function
}


const ChartShape = (props:ChartShapeProps) => {
	const CustomizedLegend = (legendProps:any) => {
		return <Box display="flex" justifyContent="center">{legendProps.payload.map((data, index)=>{
			var record = props[legendProps.type].find(x=>x.name===data.dataKey);
			const handleClick = () => {
				if( props.selected )
				{
					props.onClick(null)
				}
				else
				{
					props.onClick(record);
				}
			}

			var opacity= 1;
			if( props.selected && props.selected.generaid && props.selected.generaid !== record.generaid )
			{
				opacity= 0.2;
			}

			return <Chip key={index} style={{opacity: opacity}} onClick={handleClick} icon={<FiberManualRecordIcon style={{ color: data.color }} />} label={data.value} />
		})}
		</Box>
  };

	return <>
		<Typography variant="h3">Shape</Typography>
		<ResponsiveContainer height={100} width="100%">
			<BarChart data={[props.shapeData]}  layout="vertical" >
				<CartesianGrid strokeDasharray="3 3"/>
				<XAxis type="number"  axisLine={false} hide />
				<YAxis type="category" orientation="right" hide />
				<Tooltip content={<ShowPercentTooltip/>} />
				<Legend />
				<Bar dataKey="Cocci" stackId="a" fill="#01DFA5" />
				<Bar dataKey="Rod" stackId="a" fill="#B43104" />
			</BarChart>
		</ResponsiveContainer>

		{props.cocciKeyCollection.length > 0 && (
			<>
				<Typography variant="h3">Cocci</Typography>
				<ResponsiveContainer height={100} width="100%">
					<BarChart data={[props.cocci]}  layout="vertical"    >
						<CartesianGrid strokeDasharray="3 3"/>
						<XAxis type="number"  axisLine={false} hide />
						<YAxis type="category" dataKey="Shape" orientation="right" hide  />
						<Tooltip content={<ShowPercentTooltip/>} />
						<Legend content={<CustomizedLegend type="cocciKeyCollection" />} />
						{props.cocciKeyCollection.map((data:any)=>{
							const handleClick = () => {

								if( props.selected )
								{
									props.onClick(null)
								}
								else
								{
									props.onClick(data);
								}
							}
							var opacity= 1;
							if( props.selected && props.selected.generaid && props.selected.generaid !== data.generaid )
							{
								opacity= 0.3;
							}

							return <Bar id={data.name} key={data.name} dataKey={data.name} stackId="a" fill={ColourHash(data.name)} onClick={handleClick}>
								<Cell style={{opacity: opacity}} />
							</Bar>
						})}
						
					</BarChart>
				</ResponsiveContainer>
			</>
		)}

		{props.rodKeyCollection.length > 0 && (
			<>
				<Typography variant="h3">Rod</Typography>
				<ResponsiveContainer  height={100} width="100%">
					<BarChart data={[props.rod]}  layout="vertical">
						<CartesianGrid strokeDasharray="3 3"/>
						<XAxis type="number"  axisLine={false} hide />
						<YAxis type="category" dataKey="Shape" orientation="right" hide  />
						<Tooltip content={<ShowPercentTooltip/>} />
						<Legend content={<CustomizedLegend type="rodKeyCollection" />} />
						{props.rodKeyCollection.map((data:any)=>{
							const handleClick = () => {

								if( props.selected )
								{
									props.onClick(null)
								}
								else
								{
									props.onClick(data);
								}
							}
							var opacity= 1;
							if( props.selected && props.selected.generaid && props.selected.generaid !== data.generaid )
							{
								opacity= 0.3;
							}

							return <Bar id={data.name} key={data.name} dataKey={data.name} stackId="a" fill={ColourHash(data.name)} onClick={handleClick}>
								<Cell style={{opacity: opacity}} />
							</Bar>
						})}
						
					</BarChart>
				</ResponsiveContainer>
			</>
		)}
	</>
};

export default ChartShape;

		
