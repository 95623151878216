import React, { useEffect, useState } from 'react';
import { Card, CardContent,	makeStyles,	Grid, Box, InputLabel, colors, Container, Divider, Typography, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactSelect, { StylesConfig } from 'react-select';
import axios from 'axios';
import { getBioSystemCollection, getRegionCollection, getAgeCollection } from '../Settings/SettingsSlice';
import { useSelector } from 'react-redux';
import DashboardCard from './DashboardCard';
import Page from '../../components/Page';
import { RootState } from '../../app/store';
import { ApiBaseUrl } from '../../app/helpers';
import { Formik } from 'formik';
import * as Yup from "yup";
import SelectFormControl from "../../components/SelectFormControl";
import { createBrowserHistory } from 'history';
import { useNavigate, useParams } from 'react-router-dom';

const history = createBrowserHistory();

const dropdownStyles: StylesConfig = {
	container: (styles, state) => {
		return {
			...styles,
			position: 'static',
			'&::after':{
				content: '" "',
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				background: colors.common.white,		
				zIndex: -1,
				display: state.isFocused ? 'block' : 'none'
			}
		}
	},
	multiValueRemove: (styles, state) => {
		return {
			...styles,
			svg: {
				display: state.isDisabled ? 'none' : styles.display

			}
		}
	},
	indicatorsContainer: (styles, state) => {
		return {
			...styles,
			display: state.isDisabled ? 'none' : styles.display
		}
	},
	control: (styles) => {
		return {
			...styles,
			border: '0px',
			background: 'transparent',
    		boxShadow: 'none'
		};
	},
	multiValue: (styles) => {
		return {
			...styles,
			backgroundColor: '#adc7bf',
			borderRadius: '25px'
		};
	},
	menu: ({ width, ...css }) => ({ 
		minWidth: '200px',
		zIndex: 999,
		...css 
	})

};


const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		minHeight: '100%',
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.default,
		height: '100%',
	},
	innerCard: {
		overflow: 'visible',
		backgroundColor: theme.palette.background.default,
		borderBottom: "1px solid #e1e3e2",
		display: 'flex',
		flexFlow: 'wrap'
	},
	filterContainer:{
		padding: '0.5em',
		borderRight: "1px solid #e1e3e2", 
		position: 'relative',
		zIndex: 1,
		[theme.breakpoints.down("xs")]: {
			borderRight: "0px",
			padding: '0.2em 0px'

		},

	},
	searchBoxContainer: {
		padding: '0.5em',
		position: 'relative',
		border: '1px solid #eeeeee',
		marginBottom: '1em'
	},
	inputLabel: {
		fontWeight: 'bold',
		fontSize: '10px'
	},
	searchAgain: {
		alignSelf: 'center', 
		marginRight: 10,
		[theme.breakpoints.down("xs")]: {
			order: -2,
			margin: '1em auto'
		}
	},
	filler: {
		flexGrow: 1,
		[theme.breakpoints.down("xs")]: {
			order: -1,
			width: '100%'
		}
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
		
	  },
	  chip: {
		margin: 2,
	  },
}));

  function getCookie(name:string) : string | undefined {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0){
			return c.substring(nameEQ.length,c.length);
		}
    }
    return undefined;
}

  const genderOptions = [
	{ value: '3', label: 'Male' },
	{ value: '2', label: 'Female' },
  ]

var reactSelectCompontents = {
	IndicatorSeparator: () => null,
	Placeholder: () => null,
}


  const DashboardView = () => {
	const classes = useStyles();
	const bioSystemCollection = useSelector((state: RootState) => getBioSystemCollection(state));
	const regionCollection = useSelector((state: RootState) => getRegionCollection(state));
	const ageCollection = useSelector((state: RootState) => getAgeCollection(state));
	const searchCriteria = useSelector((state: RootState) => state.search);
	const [searchResult, setSearchResult] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const clearSearch = () => {
		navigate('/');
	}

	if( searchCriteria.biosystem.length === 0)
	{
		clearSearch();
	}

	useEffect(() => {

		setIsLoading(true);
		axios.get(`${ApiBaseUrl}/api/search`, {
			params: {
				biosystem: searchCriteria.biosystem.map((data)=>data.value),
				gender: searchCriteria.gender.map((data)=>data.value),
				region: searchCriteria.region.map((data)=>data.value),
				age: searchCriteria.age.map((data)=>data.value)
			},
			headers: {
				Authorization: `Bearer ${getCookie('jwt:auth')}`
			}
		}).then((response)=>{
			setIsLoading(false);
			setSearchResult(response.data);
		});

	}, [searchCriteria]);

	const renderResult = (biosystem) => {
		if ( isLoading )
		{
			return <>
						<Skeleton animation="wave" variant="text" width={100} />
						<Box mb={1} mt={1} display="flex" alignItems="center" justifyContent="center"><Skeleton animation="wave" variant="circle" width={100} height={100} /></Box>
						<Skeleton animation="wave" variant="text" width={100} />
		
						<Box ml={2} mt={2}><Skeleton animation="wave" variant="rect" width={210} height={5}/></Box>
						<Box ml={2} style={{marginTop:'2px'}}><Skeleton animation="wave" variant="rect" width={180} height={5} /></Box>
						<Box ml={2} style={{marginTop:'6px'}}><Skeleton animation="wave" variant="rect" width={200} height={5} /></Box>
						<Box ml={2} style={{marginTop:'2px'}}><Skeleton animation="wave" variant="rect" width={150} height={5} /></Box>
						<Box ml={2} style={{marginTop:'6px'}}><Skeleton animation="wave" variant="rect" width={192} height={5} /></Box>
						<Box ml={2} style={{marginTop:'2px'}}><Skeleton animation="wave" variant="rect" width={150} height={5} /></Box>
					</>
		}

		var data = searchResult.find(x=>x.biosystemid === biosystem.value );

		if( !data )
		{
			return <>
				<Typography variant="h2">{biosystem.label}</Typography>
				<Divider />
				<Box mt={2}></Box>
				<Typography variant="body1">No results found.</Typography>
				<Box mt={2}></Box>
				<Button size="small" variant="outlined" className={classes.searchAgain} onClick={clearSearch}>Search Again</Button>
			</>
		}

		return 	<DashboardCard label={data.label} antibiogram={data.antibiogram} data={data.data} biosystem={data.biosystemid} searchGender={searchCriteria.gender.map((data)=>data.value)} searchRegion={searchCriteria.region.map((data)=>data.value)} searchAge={searchCriteria.age.map((data)=>data.value)} />
	}

	return (
		<Page className={classes.card}>
			<Box className={classes.innerCard}>
				<Box className={classes.filterContainer}>
					<InputLabel className={classes.inputLabel}>Biosystem:</InputLabel>
					<ReactSelect isDisabled isMulti options={bioSystemCollection} value={searchCriteria.biosystem} components={reactSelectCompontents} styles={dropdownStyles} />
				</Box>
				<Box className={classes.filterContainer}>
					<InputLabel className={classes.inputLabel}>Gender:</InputLabel>
					<ReactSelect isDisabled isMulti options={genderOptions} value={searchCriteria.gender} components={reactSelectCompontents} styles={dropdownStyles} />
				</Box>
				<Box className={classes.filterContainer}>
					<InputLabel className={classes.inputLabel}>Age:</InputLabel>
					<ReactSelect isDisabled isMulti options={ageCollection} value={searchCriteria.age} components={reactSelectCompontents} styles={dropdownStyles} />
				</Box>
				<Box className={classes.filterContainer}>
					<InputLabel className={classes.inputLabel}>Region:</InputLabel>
					<ReactSelect isDisabled isMulti options={regionCollection} value={searchCriteria.region} components={reactSelectCompontents} styles={dropdownStyles} />
				</Box>
				<Box className={classes.filler} style={{ flexGrow: 1}}></Box>
				<Button variant="contained" className={classes.searchAgain} onClick={clearSearch}>Search Again</Button>
			</Box>
			
			<Box margin={3}>
				<Grid container spacing={3}>
					{searchCriteria.biosystem.map((biosystem) => (
						<Grid item xs={12} sm={6} md={4} xl={3} key={biosystem.value}>
							<Card style={{overflow: 'visible'}} square>
								<CardContent>
									{renderResult(biosystem)}
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
		</Page>
	);
  };

export default DashboardView;

