export function ColourHash(inputString:any) : string{
	var sum = 0;
	
	for(var i in inputString){
		sum += inputString.charCodeAt(i);
	}

	var r = ~~(parseFloat('0.'+Math.sin(sum+1).toString().substr(6))*256);
	var g = ~~(parseFloat('0.'+Math.sin(sum+2).toString().substr(6))*256);
	var b = ~~(parseFloat('0.'+Math.sin(sum+3).toString().substr(6))*256);

	var hex = "#";

	hex += ("00" + r.toString(16)).substr(-2,2).toUpperCase();
	hex += ("00" + g.toString(16)).substr(-2,2).toUpperCase();
	hex += ("00" + b.toString(16)).substr(-2,2).toUpperCase();

	return hex;
}

export let ApiBaseUrl = "";

switch (process.env.NODE_ENV )
{
	case 'development':
		ApiBaseUrl = 'http://127.0.0.1:8000'
		ApiBaseUrl = 'https://staging.api.janus.healthcare';
		ApiBaseUrl = 'https://api.janus.healthcare';
		break;

	case 'production':
		ApiBaseUrl = 'https://api.janus.healthcare'		
		break;
}

switch (process.env.REACT_APP_STAGE )
{
	case 'staging':
		ApiBaseUrl = 'https://staging.api.janus.healthcare';
		break;
}

