import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';

import { Box, Button, Container, TextField, Typography, makeStyles, CircularProgress, FormHelperText, FormControl } from '@material-ui/core';

import Page from '../../components/Page';
import { loginUser } from './UserSlice';


const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		position: 'relative',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3), 
		overflow: 'hidden',

		"&:after": {
			content: '" "',
			position: 'absolute',
			top: '-800px',
    		left: '26%',
			width: '1600px',
			height: '1600px',
			background: '#000000',
			opacity: 0.03,
			borderRadius:'1600px',
			zIndex: '-1'
		},
		"&:before": {
			content: '" "',
			position: 'absolute',
			top: '530px',
			left: '25%',
			width: '720px',
			height: '720px',
			background: '#000000',
			opacity: 0.02,
			borderRadius:'720px',
			zIndex: '-1'
		}
	},
	wrapper: {
		position: 'relative',
	},
	buttonProgress: {
		color: '#ffffff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	inputField: {
		'& input': {
			background: '#ffffff'
		}
	}
}));



const LoginView = () => {
  const classes = useStyles();

  const loginStatus = useSelector( (state:RootState) => state.user.loginStatus)
  const dispatch = useDispatch();


  return (
    <Page
      className={classes.root}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="xs">
		<img
      alt="Logo"
	  src="/logo_light.svg"
	  style={{height: '42px', margin: '0px auto', display: 'block', marginBottom: '3em'}}
    />
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values: any) => {
				dispatch(loginUser({
					email: values.email, 
					password: values.password
				}));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
               
            
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
				  className={classes.inputField}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
				  className={classes.inputField}
                />
				{ loginStatus === "failed" && <FormControl error><FormHelperText >You have entered an invalid email address or password. Please try again.</FormHelperText></FormControl>}

                <Box my={2} className={classes.wrapper}>
                  <Button
                    disabled={loginStatus === "loading" }
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
				  
				  { loginStatus === "loading" &&  <CircularProgress size={24} className={classes.buttonProgress} />}
                </Box>
                
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
