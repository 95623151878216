import { createSlice, nanoid, createAsyncThunk, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit'
import { __String } from 'typescript';
import axios from 'axios';

import { AppThunk, RootState } from '../../app/store';
import { ApiBaseUrl } from '../../app/helpers';

interface ConfigState {
	biosystemCollection: any[]
	regionCollection: any[]
	ageCollection: any[]
	status: String
}

const initialState: ConfigState = {
  biosystemCollection: [],
  regionCollection: [],
  ageCollection: [],
  status: 'idle',
};

function getCookie(name:string) : string | undefined {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0){
			return c.substring(nameEQ.length,c.length);
		}
    }
    return undefined;
}

export const fetchConfig = createAsyncThunk('config', async () => {
	return axios.get(`${ApiBaseUrl}/api/config`, {
		headers: {
			Authorization: `Bearer ${getCookie('jwt:auth')}`
		}
	});
})

export const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {

	},
	extraReducers: {
		[fetchConfig.pending.toString()]: (state, action) => {
			state.status = 'loading'
		},
		[fetchConfig.fulfilled.toString()]: (state, action) => {
			state.status = 'succeeded';
			// Add any fetched posts to the array
			state.biosystemCollection = action.payload.data.biosystemCollection.map((bioSystem:any)=>{
				return {
					value: bioSystem.id,
					label: bioSystem.biosystem,
					color: bioSystem.biosystemcolour
				}
			});

			state.regionCollection = action.payload.data.regionCollection.map((region:any)=>{
				return {
					value: region.id,
					label: region.region,
				}
			});

			// Remove all region option
			state.regionCollection = state.regionCollection.filter(x=>x.value !== 1);

			state.ageCollection = action.payload.data.ageCollection.map((age:any)=>{
				return {
					value: age.id,
					label: age.agedescription,
					group: age.groupname
				}
			});
		},
		[fetchConfig.rejected.toString()]: (state, action) => {
			state.status = 'failed'
		},
	},
});


export const getBioSystemCollection = (state:RootState) => state.config.biosystemCollection;

export const getRegionCollection = (state:RootState) => state.config.regionCollection;

export const getAgeCollection = (state:RootState) => state.config.ageCollection;


export default configSlice.reducer;