import React from "react";
import { Typography } from '@material-ui/core';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts'
import { ColourHash } from '../../app/helpers';
import ShowPercentTooltip from './ShowPercentTooltip';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import WarningTooltip from './WarningTooltip';
import WarningIcon from '@material-ui/icons/Warning';


interface ChartAntibioticsProps {
	data: any[]
	keyCollection: any[],
	selectedLevel: string,
}

const levelName = (value) => {
	var keyName = "";
	switch( value )
	{
		case -1:
			keyName = 'Not Set';
			break;

		case 1:
			keyName = "First";
			break;

		case 2:
			keyName = "Second";
			break;

		case 3:
			keyName = "Third";
			break;

		case 4:
			keyName = "Fourth";
			break;

		default: 
			keyName = 'Error';
			break;
	}

	return keyName;
}

const CustomizedAxisTick = (props:any) => {
	const { x, y, stroke, payload, height,width,visibleTicksCount, data, index,selectedPathogen  } = props;
	
	const newY = y - ((height/visibleTicksCount)/2) + 5;
	const record = data[index];

	var hasError = false;

	if( selectedPathogen===2 || selectedPathogen== null)
	{
		// Gram Negative
		if( record.gramNegativeTotal > 0 && record.gramNegativeTotal< 100)
		{
			hasError = true;
		}
	}

	if( selectedPathogen===4 || selectedPathogen== null)
	{
		if( record.gramPositiveTotal > 0 && record.gramPositiveTotal< 100)
		{
			hasError = true;
		}
	}


	var fill = '#666';
	if( hasError )
	{
		fill = '#e60000';
	}

	return (
		<>
	  <g transform={`translate(${(x-2)},${newY})`}>
		<text x={0} y={0} dy={16} textAnchor="end" fill={fill} >
		  {payload.value}
		</text>
	  </g>
	  </>
	);
  }

const CustomTooltip = (props:any) => {
	if (props.payload && props.payload[0] != null) 
	{
		const newPayload = props.payload.map((payload)=>{
			var newPayload =  { ...payload };
			
			var key = payload['dataKey'].replace('Value', '');
			newPayload.name = payload.name;

			if( payload.payload[key+'Total'] > 0 )
			{
				var showIcon = payload.payload[key+'Total']  < 100;
				newPayload.value = <>
				{((payload.payload[key]/payload.payload[key+'Total'])* 100).toFixed(1) + '% (' + payload.payload[key] + '/' + payload.payload[key+'Total'] + ')'}
				{showIcon && <WarningIcon fontSize="small" style={{color: '#e60000', float: 'left', marginRight: '5px'}}/>}
				</>;
			}
			else
			{
				newPayload.value = "No results"
			}

			

			return newPayload;
		});

		return <DefaultTooltipContent {...props} label={""} payload={newPayload} />;
	}
  
	return <DefaultTooltipContent {...props} />;
};



const ChartAntibiotics = (props:ChartAntibioticsProps) => {

	var showWarning = false;
	for (let index = 0; index < props.data.length; index++) {
		const element = props.data[index];
		if( element.gramNegativeTotal < 100 && element.gramNegativeTotal > 0 )
		{
			showWarning = true;
		}	

		if( element.gramPositiveTotal < 100 && element.gramPositiveTotal > 0 )
		{
			showWarning = true;
		}
	}

	return (
		<>
			<Typography variant="h3">Antibiotics {props.selectedLevel != null ? <>- {levelName(props.selectedLevel)}</> : null}{showWarning && <WarningTooltip />}</Typography>
			<ResponsiveContainer height={(props.data.length * 30) + 40} width="100%">
				<BarChart data={props.data}  layout="vertical" barSize={20} margin={{left: 150}}>
					<CartesianGrid strokeDasharray="3 3"/>
					<XAxis type="number"  axisLine={false} />
					<YAxis type="category" dataKey="antibiotic" orientation="left" interval={0} tick={<CustomizedAxisTick data={props.data} selectedPathogen={props.selectedPathogen}/>} />
					<Tooltip content={<CustomTooltip/>} />
					{ (props.selectedPathogen===2 || props.selectedPathogen==null) && <Bar dataKey={'gramNegativeValue'}  fill="#ff98f9" name="Gram Negative"/>}
					{ (props.selectedPathogen===4 || props.selectedPathogen==null) && <Bar dataKey={'gramPositiveValue'} fill="#8d1bff" name="Gram Positive"/>}
				</BarChart>
			</ResponsiveContainer>
		</>
	);
}

export default ChartAntibiotics;