import React, { Component } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';


class WarningTooltip extends Component {
	render() {
	  return <Tooltip arrow title="Contains low number of results."><WarningIcon fontSize="small" style={{color: '#e60000', float: 'right', marginRight: '5px'}}/></Tooltip>;
	}
  }

export default WarningTooltip;