import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { useDispatch, useSelector } from 'react-redux';

import GlobalStyles from './components/GlobalStyles';
import { getUser, loadUser } from './features/User/UserSlice';

import { fetchConfig } from './features/Settings/SettingsSlice';
import { BrowserRouter,
	Routes,
	Route } from 'react-router-dom';

import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import DashboardView from './features/Dashboard/DashboardView';
import SearchView from './features/Dashboard/SearchView';

import SettingsView from './features/Settings/SettingsView';

import PractiseInviteView from './features/User/PractiseInviteView';

import ProtectedRoute from './components/AuthRoute';
import { RootState } from './app/store';
import Loader from "react-loader";
import { hotjar } from 'react-hotjar';

const App = () => {
	const dispatch = useDispatch();
	const loadStatus = useSelector( (state:RootState) => state.user.loadStatus)
	const user = useSelector((state: RootState) => getUser(state));
	const isLoggedIn = user === null ? false : true;
	

	hotjar.initialize(2240296, 6);

	useEffect(() => {
		dispatch(loadUser());
		dispatch(fetchConfig());
	}, [dispatch, isLoggedIn]);

	if( loadStatus === "loading" )
	{
		return  <Loader
        loaded={false}
        lines={5}
        length={5}
        width={29}
        radius={26}
        corners={1}
        rotate={0}
        direction={1}
        color="#00f6fa"
        speed={1}
        trail={60}
        shadow={false}
        hwaccel={false}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="50%"
        scale={1.0}
        loadedClassName="loadedContent"
      />;
	}
  
	return (
	  	<ThemeProvider theme={theme}>
			<GlobalStyles />
			<BrowserRouter>
      			<Routes>
					<Route path="/practiceinvite/:inviteKey"><PractiseInviteView /></Route>
					<ProtectedRoute path="/" element={<DashboardLayout />}>
						<Route path="/"><SearchView /></Route>
						<Route path="/result"><DashboardView /></Route>
						<Route path="/settings"><SettingsView /></Route>
        			</ProtectedRoute>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
  };
  
  export default App;
