// @ts-nocheck

import { FormControl, InputLabel, FormHelperText, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import React, { useState } from 'react';
import Select, { StylesConfig} from 'react-select';

const useStyles = makeStyles((theme) => ({
	inputBase: {
		'& fieldset': {
			top: '-5px',
			left: 0,
			right: 0,
			bottom: 0,
			margin: 0,
			padding: '0 8px',
			overflow: 'hidden',
			position: 'absolute',
			borderStyle: 'solid',
			borderWidth: '1px',
			borderRadius: 'inherit',
			pointerEvents: 'none',
			borderColour: 'rgba(0, 0, 0, 0.23)'
		},

		'&.Mui-error fieldset': {
			borderColor: '#f44336'
		},

		'&:hover': {
			'& fieldset': {
				borderColor: '#263238'
			}
		}
	}

	
}));

const dropdownStyles: StylesConfig = {
	container: (styles, state) => {
		return {
			...styles,
			width: '100%',
		}
	},
	control: (styles) => {
		return {
			...styles,
			border: '0px',
			background: 'transparent',
			minWidth: '100px',
			boxShadow: 'none',
			padding: '10px 0px'
			
		};
	},
	multiValue: (styles) => {
		return {
			...styles,
			backgroundColor: '#adc7bf',
			borderRadius: '25px'
		};
	},
	menu: ({ width, ...css }) => ({ 
		minWidth: '200px',
		zIndex: 999,
		...css 
	})

};


var reactSelectCompontents = {
	IndicatorSeparator: () => null,
	Placeholder: () => null
}


	
const SelectFormControl = (props) => {
	const [isFocused, setFocused] = useState(false);
	const classes = useStyles();
	return <Field name={props.name} id={props.name} type="number">
		{({ field, form, meta }) => {
			const handleChange = (option: Option) => {
				if( props.isMulti )
				{
					form.setFieldValue(props.name, option.map((data)=>data.value));
				}
				else
				{
					form.setFieldValue(props.name, option.value);
				}
				
			}

			const handleFocus = () => {
				setFocused(true);
			}

			const handleBlur = () => {
				setFocused(false);
			}
			var shrinkLabel = false;
			if( isFocused || (props.isMulti && (field.value != null && field.value.length > 0)) || (props.isMulti!==true && field.value !== ""))
			{
				shrinkLabel = true;
			}

			var touched = form.touched[props.name];
			var errors = form.errors[props.name];

			var initialValue;
			if( props.isMulti )
			{
				initialValue = [];
				for (let index = 0; index < meta.initialValue.length; index++) {
					const element = meta.initialValue[index];
					initialValue.push(props.options.find(x => x.value === element));
				}
			}			

			return (
				<FormControl variant="outlined" color="primary" margin="normal" fullWidth error={Boolean(touched && errors)}>
					<InputLabel id="biosystem" style={{ background: "#fff", paddingRight: "5px", zIndex: 0 }} shrink={shrinkLabel}>
					{props.label}
					</InputLabel>
					<div className={"MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiOutlinedInput-root " + classes.inputBase + " "  + (touched && errors ? " Mui-error" : "")} style={{
						position: 'relative',
						borderRadius: '4px'
					}}>
						<Select isSearchable={ false } isMulti={props.isMulti} options={props.options} components={reactSelectCompontents} styles={dropdownStyles} defaultValue={initialValue} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} />
						<fieldset aria-hidden="true" className={"MuiOutlinedInput-notchedOutline "}><legend style={{
								width: 'auto',
								height: '11px',
								display: 'block',
								padding: '0',
								fontSize: '0.75em',
								textAlign: 'left',
								visibility: 'hidden',
								maxWidth: shrinkLabel ? '1000px' : '0.01px',
								transition: shrinkLabel ? 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms' : 'max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
							}}><span style={{
								display: 'inline-block',
								paddingLeft: '5px',
								paddingRight: '5px'
							}}>{props.label}</span></legend></fieldset>
					</div>
					{touched && errors && (<FormHelperText>{errors}</FormHelperText>)}
				</FormControl>
			);
		}}
	</Field>
};



export default SelectFormControl;
