import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import janusColors from './janusColors';

const theme = createMuiTheme({

  palette: {
    background: {
      dark: '#F4F6F8',
      default: '#f4f7f6',
      paper: colors.common.white
	},
	default: {

    },
    primary: {
		main: '#1a1c23'

    },
    secondary: {
		main: '#adc7bf'

    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography,
  overrides:{
    MuiButton:{
		root: {
			borderRadius: 20,
		  }, 
		  sizeSmall: {
        "& $label": {
			fontSize: 11,
			textTransform: 'none',
			lineHeight: '1.6'
		},
		
      },
      contained:{
        backgroundColor: '#4360ee',
		color: '#ffffff',
		'&:hover': {
			backgroundColor: '#0c228b',
			color: '#ffffff',
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
			  backgroundColor: '#4360ee',
			},
		  }
      }
    }
  }
});

export default theme;
