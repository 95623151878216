import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  FormHelperText,
  CircularProgress
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getRegionCollection } from "../SettingsSlice";
import { getUser, updatePassword } from "../../User/UserSlice";
import { RootState } from "../../../app/store";
import { Formik } from "formik";
import * as Yup from "yup";



const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column",
  },
  buttonProgress: {
	color: '#ffffff',
	position: 'absolute',
	top: '50%',
	left: '50%',
	marginTop: -12,
	marginLeft: -12,
},
wrapper: {
	position: 'relative',
},
});

const Password  = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const regionCollection = useSelector((state: RootState) =>
    getRegionCollection(state)
  );
  const user = useSelector((state: RootState) => getUser(state));
  const updatePasswordStatus = useSelector( (state:RootState) => state.user.updatePasswordStatus)

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required("A password is required"),
        confirmPassword: Yup.string().when("password", {
			is: val => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
			  [Yup.ref("password")],
			  "Confirm password need to be the same"
			)
		  })

      })}
      onSubmit={(values: any) => {
		dispatch(updatePassword(values));
	  }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form className={clsx(classes.root, className)} {...rest} onSubmit={handleSubmit}>
          <Card>
			<CardHeader
			subheader="Update password"
			title="Password"
			/>
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item sm={6} >
				<TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                </Grid>
				<Grid className={classes.item} item sm={6} >
				<TextField
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Confirm Password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />
                </Grid>
               
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2} >
						<Box className={classes.wrapper}>
			<Button
                    disabled={updatePasswordStatus === "loading" }
                    
                    type="submit"
                    variant="contained"
                  >
                    Update Password
                  </Button>
				  
				  { updatePasswordStatus === "loading" &&  <CircularProgress size={24} className={classes.buttonProgress} />}
            </Box>
			</Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default Password;
