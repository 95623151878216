import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  FormHelperText,
  CircularProgress
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getRegionCollection } from "../SettingsSlice";
import { getUser, updateSettings } from "../../User/UserSlice";
import { RootState } from "../../../app/store";
import { Formik } from "formik";
import * as Yup from "yup";



const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column",
  },
  buttonProgress: {
	color: '#ffffff',
	position: 'absolute',
	top: '50%',
	left: '50%',
	marginTop: -12,
	marginLeft: -12,
},
wrapper: {
	position: 'relative',
},
});

const AccountDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const regionCollection = useSelector((state: RootState) =>
    getRegionCollection(state)
  );
  const user = useSelector((state: RootState) => getUser(state));
  const updateStatus = useSelector( (state:RootState) => state.user.updateStatus)

  return (
    <Formik
      initialValues={{
        userregion: user.userregion,
        communicareid: user.communicareid,
        ahpranumber: user.ahpranumber,
      }}
      validationSchema={Yup.object().shape({
        ahpranumber: Yup.string().max(255).required("Ahpra number is required"),
        communicareid: Yup.string()
          .max(255)
          .required("Communicare Id is required"),
        userregion: Yup.string().max(255).required("A region is required"),
      })}
      onSubmit={(values: any) => {
		dispatch(updateSettings(values));
	  }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form className={clsx(classes.root, className)} {...rest} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Manage account details"
              title="Account Details"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={Boolean(touched.userregion && errors.userregion)}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{ background: "#fff", paddingRight: "5px" }}
                    >
                      Default Region
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Default Region"
                      name="userregion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.userregion}
                    >
                      {regionCollection.map((region) => (
                        <MenuItem value={region.value} key={region.value}>{region.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.userregion && errors.userregion}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* <Grid className={classes.item} item md={4} sm={6} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.communicareid && errors.communicareid
                    )}
                    fullWidth
                    helperText={touched.communicareid && errors.communicareid}
                    label="Communicare Id"
                    margin="normal"
                    name="communicareid"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="communicareid"
                    value={values.communicareid}
                    variant="outlined"
                  />
                </Grid> */}
                <Grid className={classes.item} item md={4} sm={6} xs={12}>
                  <TextField
                    error={Boolean(touched.ahpranumber && errors.ahpranumber)}
                    fullWidth
                    helperText={touched.ahpranumber && errors.ahpranumber}
                    label="Ahpra Number"
                    margin="normal"
                    name="ahpranumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="ahpranumber"
                    value={values.ahpranumber}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2} >
						<Box className={classes.wrapper}>
			<Button
                    disabled={updateStatus === "loading" }
                    
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
				  
				  { updateStatus === "loading" &&  <CircularProgress size={24} className={classes.buttonProgress} />}
            </Box>
			</Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
};

export default AccountDetails;
